<route>
{
  "meta": {
    "permission": [
      "sales.view_order",
      "sales.view_orderproduct"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-tabs
      class="mb-2"
      color="primary"
      next-icon="fa-chevron-right"
      prev-icon="fa-chevron-left"
      centered
      show-arrows
      :icons-and-text="$vuetify.breakpoint.smAndUp"
      v-model="tabs"
    >
      <v-tab class="pt-3">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['all'])"
        >
          <span
            class="pt-2"
            v-if="$vuetify.breakpoint.smAndUp"
            v-text="$t('all')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">fas fa-file</v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#draft">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['draft'])"
        >
          <span
            v-show="$vuetify.breakpoint.smAndUp"
            class="pt-2"
            v-text="$t('draft')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">
            fas fa-file-signature
          </v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#quote">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['quote'])"
        >
          <span
            v-show="$vuetify.breakpoint.smAndUp"
            class="pt-2"
            v-text="$t('quote')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">
            fa-file-contract
          </v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#sended_quote">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['sended_quote'])"
        >
          <span
            v-show="$vuetify.breakpoint.smAndUp"
            class="pt-2"
            v-text="$t('sended_quote')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">
            fa-file-contract
          </v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#in_process">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['in_process'])"
        >
          <span
            v-show="$vuetify.breakpoint.smAndUp"
            class="pt-2"
            v-text="$t('in_process')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">fa-file-image</v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#production_ready">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['production_ready'])"
        >
          <span
            v-show="$vuetify.breakpoint.smAndUp"
            class="pt-2"
            v-text="$t('production_ready')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">
            fa fa-shipping-fast
          </v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#delivery">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['delivery'])"
        >
          <span
            v-show="$vuetify.breakpoint.smAndUp"
            class="pt-2"
            v-text="$t('delivery')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">
            fa fa-shipping-fast
          </v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#finished">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['finished'])"
        >
          <span
            v-show="$vuetify.breakpoint.smAndUp"
            class="pt-2"
            v-text="$t('finished')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">fa-file-image</v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#correction">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['correction'])"
        >
          <span
            v-show="$vuetify.breakpoint.smAndUp"
            class="pt-2"
            v-text="$t('correction')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">fa-file-archive</v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#canceled">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['canceled'])"
        >
          <span
            v-show="$vuetify.breakpoint.smAndUp"
            class="pt-2"
            v-text="$t('canceled')"
          >
          </span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">fa-file-excel</v-icon>
        </v-badge>
      </v-tab>
    </v-tabs>
    <v-card>
      <div v-if="tabs === 0">
        <i-card-list
          :title="$tc('suborder', 2)"
          app="sales.order"
          api="sale.product"
          dontCreate
          dontRemove
          dontEdit
          simple
          :opt="{ company_id: this.company.pk }"
        >
          <template v-slot:[`item`]="{ item, showMode }">
            <v-card-text>
              <v-row class="my-0">
                <!-- Imagen: (solo si showMode es grid o list) -->
                <v-col
                  v-if="showMode !== 'simple'"
                  class="d-flex justify-content-center"
                  :cols="showMode === 'grid' ? 12 : 2"
                >
                  <i-image
                    class="product-image"
                    :style="{
                      width: '10em !important',
                      height: '8em !important'
                    }"
                    :value="item.thumbnail"
                    :preview="true"
                    :readonly="true"
                  />
                </v-col>

                <!-- Cliente, total y vendedor -->
                <v-col
                  class="d-flex flex-column justify-content-center pt-0 g-1"
                  cols="12"
                  :md="showMode != 'grid' ? 2 : 12"
                >
                  <p class="mb-0">
                    <span class="primary--text font-weight-black">
                      {{ `${$tc('customer', 1)}: ` }}
                    </span>
                    <span class="primary--text subtitle-2">
                      {{ item.clientName }}
                    </span>
                  </p>
                  <p class="mb-0">
                    <span class="primary--text font-weight-black">
                      {{ `${$tc('total', 1)}: ` }}
                    </span>
                    <span class="primary--text subtitle-2">
                      {{ item.total }}
                    </span>
                  </p>
                  <p class="mb-0">
                    <span class="primary--text font-weight-black">
                      {{ `${$tc('salesman', 1)}: ` }}
                    </span>
                    <span class="primary--text subtitle-2">
                      {{ item.salesmanName }}
                    </span>
                  </p>
                </v-col>

                <!-- Subórdenes -->
                <v-col
                  class="pt-0"
                  cols="12"
                  :md="showMode == 'simple' ? 7 : showMode == 'list' ? 5 : 12"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span
                          class="primary--text subtitle-2 font-weight-black"
                        >
                          {{ $tc('suborder', 1) }} ({{ item.own_prod }})
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <suborder :order="item.order" :subOrder="item.pk" />
                </v-col>

                <!-- Fecha, fecha compromiso y estado -->
                <v-col
                  class="d-flex flex-column justify-content-center g-1 grey--text"
                  cols="12"
                  :md="showMode != 'grid' ? 3 : 12"
                >
                  <p class="text-right mb-0 font-weight-bold caption">
                    <span>{{ $t('date') }}</span>
                    <span class="primary--text">
                      {{ `${dateFormat(item.orderDate)}` }}
                    </span>
                  </p>
                  <p
                    v-if="item.promiseDate != null"
                    class="text-right mb-0 font-weight-bold caption"
                  >
                    <span>
                      {{ `${$t('promise_date')}` }}
                    </span>
                    <span class="primary--text">
                      {{ `${dateFormat(item.promiseDate)}` }}
                    </span>
                  </p>
                  <p
                    v-if="item.dateDone != null"
                    class="text-right mb-0 font-weight-bold caption"
                  >
                    <span>{{ `${$t('dateDone')}` }}</span>
                    <span class="primary--text">
                      {{ `${dateFormat(item.dateDone)}` }}
                    </span>
                  </p>
                  <p class="text-right mb-0 font-weight-bold caption">
                    <span>{{ `${$t('orderstatus')}:` }}</span>
                    <span class="primary--text font-weight-black">
                      {{ `${$t(item.orderMain)}` }}
                    </span>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </i-card-list>
      </div>
      <div v-if="tabs !== 0">
        <i-card-list
          class="mt-2 px-2"
          api="sale"
          app="sales.order"
          dontHeader
          dontColor
          dontRemove
          simple
          :method="'list'"
          :opt="{ status: this.tabs, company_id: this.company.pk }"
          :reload.sync="reload"
          :title="$t('salesorderlist')"
          @click:create="orderBtn.add ? changeRoute({ pk: 'create' }) : ''"
          @click:edit="orderBtn.edit ? changeRoute({ pk: $event.pk }) : ''"
        >
          <template v-slot:[`item`]="{ item, showMode, remove }">
            <v-card-text class="pt-0">
              <v-row class="my-0">
                <!-- Imagen: (solo si showMode es grid o list) -->
                <v-col
                  v-if="showMode !== 'simple'"
                  class="d-flex justify-content-center"
                  :cols="showMode === 'grid' ? 12 : 2"
                >
                  <i-image
                    class="product-image"
                    :style="{
                      width: '10em !important',
                      height: '8em !important'
                    }"
                    :value="item.thumbnail"
                    :preview="true"
                    :readonly="true"
                  />
                </v-col>

                <!-- Cliente, total y vendedor -->
                <v-col
                  class="d-flex flex-column justify-content-center pt-0 g-1"
                  cols="12"
                  :md="showMode != 'grid' ? 2 : 12"
                >
                  <p class="primary--text subtitle-1 font-weight-bold mb-0">
                    {{ `${item.nameOrder}` }}
                  </p>
                  <p class="mb-0">
                    <span class="primary--text font-weight-black">
                      {{ `${$tc('customer', 1)}: ` }}
                    </span>
                    <span class="primary--text subtitle-2">
                      {{ item.name_client }}
                    </span>
                  </p>
                  <p class="mb-0">
                    <span class="primary--text font-weight-black">
                      {{ `${$tc('salesman', 1)}: ` }}
                    </span>
                    <span class="primary--text subtitle-2">
                      {{ item.name_salesman }}
                    </span>
                  </p>
                </v-col>

                <!-- Subórdenes -->
                <v-col
                  cols="12"
                  :md="showMode == 'simple' ? 7 : showMode == 'list' ? 5 : 12"
                >
                  <v-list v-if="item.number_suborder > 0" class="py-0" dense>
                    <v-list-group @click.stop="">
                      <v-icon slot="appendIcon" size="12">
                        fas fa-arrow-down
                      </v-icon>
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span
                              class="primary--text subtitle-2 font-weight-black pl-2"
                            >
                              {{
                                item.number_suborder != 1
                                  ? $tc('suborder', 2)
                                  : $tc('suborder', 1)
                              }}
                              ({{ item.number_suborder }})
                            </span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <suborder :order="item.pk" />
                    </v-list-group>
                  </v-list>
                </v-col>

                <!-- Eliminar, fecha, fecha compromiso y estado -->
                <v-col
                  class="d-flex flex-column justify-content-center g-1 grey--text"
                  cols="12"
                  :md="showMode != 'grid' ? 3 : 12"
                >
                  <p class="primary--text caption text-right mb-2">
                    {{ `${item.code != null ? item.code : ''}` }}
                    <v-menu
                      v-if="orderBtn.delete && item.pk"
                      class="mx-0"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="pr-0"
                          color="primary"
                          text
                          small
                          dark
                          v-on="on"
                          @click="getCartList(item)"
                        >
                          {{ $t('action') }}
                          <v-icon class="ml-2" size="10">
                            fa-chevron-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <div
                        v-if="order != null && typeof customer === 'object'"
                        v-show="false"
                      >
                        <quote
                          :status.sync="order.mainStatus"
                          :cartlist.sync="cartlist"
                          :order="order"
                          :customer="customer"
                          @done="getSaleOrders(newStatus)"
                          ref="quote"
                        />
                      </div>
                      <v-list>
                        <v-list-item>
                          <i-btn
                            classes="mx-1"
                            color="error"
                            icon="fa-trash"
                            outlined
                            :title="$tc('delete', 1)"
                            @click.prevent="remove(item.pk)"
                          />
                        </v-list-item>
                        <v-list-item v-if="item.mainStatus === 'quote'">
                          <i-btn
                            classes="mx-1"
                            color="success"
                            icon="fa-paper-plane"
                            outlined
                            :title="$tc('send_quote')"
                            @click.prevent="update('send')"
                          />
                        </v-list-item>
                        <v-list-item
                          v-if="
                            item.mainStatus === 'quote' ||
                            item.mainStatus === 'sended_quote'
                          "
                        >
                          <i-btn
                            classes="mx-1"
                            color="success"
                            icon="fa-check"
                            outlined
                            :title="$t('aproved_quote')"
                            @click.prevent="update('approved')"
                          />
                        </v-list-item>
                        <v-list-item v-if="item.mainStatus === 'in_process'">
                          <i-btn
                            classes="mx-1"
                            color="warning"
                            icon="fa-check"
                            outlined
                            :title="$t('production_ready')"
                            @click.prevent="update('production_ready')"
                          />
                        </v-list-item>
                        <v-list-item v-if="item.mainStatus === 'sended_quote'">
                          <i-btn
                            classes="mx-1"
                            color="error"
                            icon="fa-times"
                            outlined
                            :title="
                              $t('cancel_specific', { value: $t('quote') })
                            "
                            @click.prevent="update('canceled')"
                          />
                        </v-list-item>
                        <v-list-item v-if="item.mainStatus === 'quote'">
                          <i-btn
                            classes="mx-1"
                            color="info"
                            icon="fa-cart-plus"
                            outlined
                            :title="`${$t('add', {
                              value: $tc('product', 2)
                            })}`"
                            @click.stop="changeRoute(item, 'addProd')"
                          />
                        </v-list-item>
                        <v-list-item v-if="item.mainStatus !== 'draft'">
                          <i-btn
                            :title="$t('download', { value: $t('quote') })"
                            outlined
                            classes="mx-1"
                            icon="fa-file-download"
                            color="error"
                            @click="getPdf(true)"
                          />
                        </v-list-item>
                        <v-list-item v-if="item.mainStatus !== 'draft'">
                          <i-btn
                            :title="$t('print', { value: $t('quote') })"
                            outlined
                            classes="mx-1"
                            icon="fa-file-pdf"
                            color="error"
                            @click="getPdf()"
                          />
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </p>

                  <p class="text-right mb-0 font-weight-bold caption">
                    <span>{{ $t('date') }}</span>
                    <span class="primary--text">
                      {{ `${dateFormat(item.date)}` }}
                    </span>
                  </p>
                  <p
                    v-if="item.promise_date != null"
                    class="text-right mb-0 font-weight-bold caption"
                  >
                    <span>
                      {{ `${$t('promise_date')}` }}
                    </span>
                    <span class="primary--text">
                      {{ `${dateFormat(item.promise_date)}` }}
                    </span>
                  </p>
                  <p
                    v-if="item.dateDone != null"
                    class="text-right mb-0 font-weight-bold caption"
                  >
                    <span>{{ `${$t('dateDone')}` }}</span>
                    <span class="primary--text">
                      {{ `${dateFormat(item.dateDone)}` }}
                    </span>
                  </p>
                  <p class="text-right mb-0 font-weight-bold caption">
                    <span>{{ `${$t('orderstatus')}:` }}</span>
                    <span class="primary--text font-weight-black">
                      {{ `${$t(item.mainStatus)}` }}
                    </span>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </i-card-list>
      </div>
      <v-btn
        v-show="!$store.getters.getIsloading"
        class="mr-3"
        bottom
        color="secondary"
        dark
        fab
        fixed
        right
        v-scroll="onScroll"
        @click="$vuetify.goTo(positionScroll ? 0 : '#pagination')"
      >
        <v-icon
          dark
          v-text="positionScroll ? 'fa-chevron-up' : 'fa-chevron-down'"
        >
        </v-icon>
      </v-btn>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import suborder from '../../components/sale/MiniSuborder.vue'
import quote from '../../components/sale/quotation.vue'

export default {
  components: {
    suborder,
    quote
  },
  data() {
    return {
      orderStatus: {
        all: 0,
        draft: 0,
        pdocument: 0,
        sended_document: 0,
        in_process: 0,
        production_ready: 0,
        delivery: 0,
        reception: 0,
        finished: 0,
        canceled: 0,
        correction: 0
      },
      orders: [],
      loading: false,
      positionScroll: 0,
      enableSearch: false,
      tabs: 'all',
      reload: false,
      order: null,
      customer: null,
      cartlist: [],
      newStatus: ''
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      company: 'company/getCompanyData'
    }),
    orderBtn() {
      return {
        add:
          (this.getPermissions([`sales.add_order`]) &&
            this.getPermissions([`base_config.view_term`]) &&
            this.getPermissions([`customer.view_customer`]) &&
            this.getPermissions([`base_config.view_level`])) ||
          this.isAdmin,
        edit:
          (this.getPermissions([`sales.change_order`]) &&
            this.getPermissions([`base_config.view_term`]) &&
            this.getPermissions([`customer.view_customer`]) &&
            this.getPermissions([`product.view_product`]) &&
            this.getPermissions([`product.view_producttemplate`]) &&
            this.getPermissions([`base_config.view_level`]) &&
            this.getPermissions([`base_config.view_globaltax`]) &&
            this.getPermissions([`sales.view_orderproduct`]) &&
            this.getPermissions([`sales.add_quotation`]) &&
            this.getPermissions([`sales.view_assigneduser`]) &&
            this.getPermissions([`base_config.view_productiondepartment`])) ||
          this.isAdmin,
        delete: this.getPermissions([`sales.delete_order`]) || this.isAdmin
      }
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.positionScroll = top > 60
    },
    async getSaleOrders(newStatus) {
      try {
        this.loading = true
        const res = await this.$api.sale.list({
          opt: {
            params: {
              company_id: this.company.pk,
              status: this.tabs
            }
          }
        })
        this.orders = res.data.results
        const status = await this.$api.sale.counter({
          opt: {
            params: {
              company_id: this.company.pk
            }
          }
        })
        this.orderStatus = status.data
        this.orderStatus.all =
          status.data.draft +
          status.data.quote +
          status.data.sended_quote +
          status.data.in_process +
          status.data.production_ready +
          status.data.delivery +
          status.data.finished +
          status.data.correction +
          status.data.canceled
      } finally {
        this.loading = false
      }
      if (newStatus) this.getNewTab(newStatus)
    },
    /**
     * getCustomer
     * Método para filtrar la información del cliente de la orden
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getCustomer(item) {
      let customer = await this.$api.customer.show({
        pk: item.customer
      })
      this.customer = customer.data
    },
    /**
     * getCartList
     * Método para filtrar los productos de la orden
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getCartList(item) {
      try {
        await this.getCustomer(item)
        const { data: cart } = await this.$api.sale.product.show({
          pk: item.pk,
          opt: { params: { order: true } }
        })
        this.order = item
        this.cartlist = cart
        if (this.cartlist.length === 0 && this.order.mainStatus === 'quote') {
          this.$toast.error(this.$tc('select_prod', 1))
        }
      } catch (error) {
        console.error('Error al obtener la lista del carrito:', error)
      }
    },
    /**
     * update
     * Método para cambiar de estado la orden
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    update(mode) {
      this.$refs['quote'].update(mode)
      this.newStatus = mode
    },
    /**
     * getNewTab
     * Método para cambiar de pestaña al nuevo estado de orden
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getNewTab(mode) {
      this.tabs = mode
      if (mode == 'send') this.tabs = 'sended_quote'
      if (mode == 'approved') this.tabs = 'in_process'
    },
    changeRoute(item, addProd) {
      this.$router.push({
        name: 'sale-pk',
        params: {
          pk: this.codification({ code: item.pk }),
          step: addProd
            ? this.codification({ code: 2 })
            : this.codification({ code: 0 })
        }
      })
    },
    getPdf(down) {
      this.downloadFile({
        api: 'quotation',
        method: 'pdf',
        filename: 'Quotation',
        action: down ? 'download' : 'window',
        ext: 'pdf',
        opt: { pk: this.order.pk }
      })
    }
  },
  mounted() {
    this.getSaleOrders()
  },
  watch: {
    tabs() {
      this.getSaleOrders()
    },
    '$vuetify.breakpoint.xsOnly': {
      handler(val) {
        if (val) {
          this.showMode = 'simple'
        }
      }
    }
  }
}
</script>
<style lang="sass">
.theme--dark.v-tabs > .v-tabs-bar
  background-color: transparent
</style>
